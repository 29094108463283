import * as React from 'react'
import type { ButtonProps as AntdButtonProps } from 'antd'
import { Button as AntdButton } from 'antd'

export interface ButtonProps {
  type?: 'link' | 'text' | 'ghost' | 'primary' | 'dashed'
  className?: string
  htmlType?: 'button' | 'submit' | 'reset'
  loading?: boolean
  ref?: React.MutableRefObject<null>
}

export default function Button({
  type = 'primary',
  className,
  htmlType,
  loading,
  ...props
}: ButtonProps & AntdButtonProps) {
  return (
    <AntdButton
      className={`text-sm uppercase font-bold rounded flex justify-center items-center ${className || ''}`}
      type={type}
      htmlType={htmlType}
      loading={loading}
      {...props}
    />
  )
}
